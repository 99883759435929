/* eslint-disable @typescript-eslint/no-explicit-any */

export class FepLogger {
  private static _instance: FepLogger;

  private get loggingEnabled() {
    return process.env.NODE_ENV === "development";
  }

  static getInstance(): FepLogger {
    if (!this._instance) {
      this._instance = new FepLogger();
    }

    return this._instance;
  }

  error(...data: any[]) {
    if (this.loggingEnabled) {
      console.error(...data);
    }
  }

  warn(...data: any[]) {
    if (this.loggingEnabled) {
      console.warn(...data);
    }
  }

  info(...data: any[]) {
    if (this.loggingEnabled) {
      console.info(...data);
    }
  }
}

export const logger = Object.freeze(FepLogger.getInstance()) as FepLogger;
