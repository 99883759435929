import * as gigyaFunctions from "@fep/gigyaFunction";
import { loadScript } from "@fep/services";
import { FepConfig, config } from "@fep/services/config";
import { getSsoGigyaBaseUrl } from "@fep/gigyaFunction/getSsoGigyaBaseUrl";

export interface GigyaLibManagerMixin {
  config: FepConfig;
}

export class GigyaLibManager {
  private static _instance: GigyaLibManager;
  private _initialized = false;

  // mixin
  config: FepConfig;

  private constructor(mixin: GigyaLibManagerMixin) {
    this.config = mixin.config;
  }

  static getInstance(mixin: GigyaLibManagerMixin): GigyaLibManager {
    if (!this._instance) {
      this._instance = new GigyaLibManager(mixin);
    }

    return this._instance;
  }

  get initialized() {
    return this._initialized;
  }

  async init() {
    if (!this.initialized) {
      await this._loadScript();
      this._patchGigya();

      this._initialized = true;
    }
  }

  private async _loadScript() {
    const gigyaScriptUrl = this._getGigyaUrl();
    await loadScript(gigyaScriptUrl);
  }

  private _patchGigya() {
    Object.assign(window.gigya.thisScript.globalConf, gigyaFunctions);
  }

  private _getSsoGigyaUrl() {
    const baseUrl = getSsoGigyaBaseUrl();
    return new URL(`${baseUrl}/js/gigya.js`);
  }

  private _getGigyaUrl() {
    const gigyaScriptUrl = this.config.data.ssoEnabled ? this._getSsoGigyaUrl() : new URL("https://cdns.gigya.com/js/gigya.js");

    gigyaScriptUrl.searchParams.set("apiKey", this.config.data.apiKey);
    gigyaScriptUrl.searchParams.set("lang", this.config.data.lang);

    return gigyaScriptUrl;
  }
}

export const gigyaLibManager = GigyaLibManager.getInstance({ config });
