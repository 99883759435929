import { getAccountInfo } from "@fep/functions";
import { IGigyaEvents } from "@fep/interfaces";
import { logger } from "@fep/services";

export async function onLogin(callback: IGigyaEvents["onLogin"]) {
  try {
    const account = await getAccountInfo();
    await callback!(account);
  } catch (error) {
    logger.error(error);
  }
}
