import { IAddEventHandlersOptions, IGigyaEvents } from "@fep/interfaces";
import { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { onLogin } from "./onLogin";
import { onLogout } from "./onLogout";
import { FepLogger, logger } from "@fep/services";

export interface FepGigyaEventsMixin {
  logger: FepLogger;
}

export class FepGigyaEvents {
  private static _instance: FepGigyaEvents;
  private _listeners: IGigyaEvents;

  // mixin
  logger: FepLogger;

  private constructor(mixin: FepGigyaEventsMixin) {
    this.logger = mixin.logger;

    this._listeners = {};
  }

  static getInstance(mixin: FepGigyaEventsMixin): FepGigyaEvents {
    if (!this._instance) {
      this._instance = new FepGigyaEvents(mixin);
    }

    return this._instance;
  }

  get listeners() {
    return this._listeners;
  }

  addEventListener(type: "login", listener: (account: ISapAccount) => void | Promise<void>): void;
  addEventListener(type: "logout", listener: () => void | Promise<void>): void;
  addEventListener(
    type: "login" | "logout",
    listener: ((account: ISapAccount) => void | Promise<void>) | (() => void | Promise<void>)
  ): void {
    switch (type) {
      case "login":
        this.onLogin = listener as (account: ISapAccount) => void | Promise<void>;
        break;
      case "logout":
        this.onLogout = listener as () => void | Promise<void>;
        break;
      default:
        this.logger.warn("Event type is not provided.");
        break;
    }
  }

  set onLogin(onLoginCallback: (account: ISapAccount) => void | Promise<void>) {
    if (this.listeners.onLogin !== onLoginCallback) {
      this.listeners.onLogin = onLoginCallback;

      const events: IAddEventHandlersOptions = {
        onLogin: async () => onLogin(this.listeners.onLogin)
      };

      this.subscribe(events);
    }
  }

  set onLogout(onLogoutCallback: () => void | Promise<void>) {
    if (this.listeners.onLogout !== onLogoutCallback) {
      this.listeners.onLogout = onLogoutCallback;

      const events: IAddEventHandlersOptions = {
        onLogout: async () => onLogout(this.listeners.onLogout)
      };

      this.subscribe(events);
    }
  }

  subscribe(events: IAddEventHandlersOptions = {}) {
    try {
      window.gigya.accounts.addEventHandlers(events);
    } catch (err) {
      this.logger.warn(`Cannot add event handlers. Gigya is possibly not initialized.`);
    }
  }
}

export const events = FepGigyaEvents.getInstance({ logger });
